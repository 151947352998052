/*
 * @Descripttion:
 * @Author: xiang.gao 1543865938
 * @Date: 2022-03-23 18:33:54
 * @LastEditors: xiang.gao 1543865938
 * @LastEditTime: 2022-06-17 11:55:52
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getSalesInteractionsList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/index",
      params
    );
  },
  addSalesInteractions(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/store",
      params
    );
  },
  updateSalesInteractions(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/update",
      params
    );
  },
  deleteSalesInteractions(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/delete",
      params
    );
  },
  exportSalesInteractions(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/export",
      params,
      { responseType: "blob" }
    );
  },
  exportSalesInteractionsMonthKpi(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/download-month-kpi",
      params,
      { responseType: "blob" }
    );
  },
  getSalesInteractionsInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sales-interactions/show",
      params
    );
  },
  getInfluencerSalesInteraction(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/sales-interactions/get-influencer-sales-interaction",
      params
    );
  },
  getTimelineOfTask(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/sales-interactions/get-timeline-of-task",
      params
    );
  },
};
