import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getCampaignList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/index",
      params
    );
  },
  addCampaign(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/store",
      params
    );
  },
  updateCampaign(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/update",
      params
    );
  },
  deleteCampaign(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/delete",
      params
    );
  },
  getCampaignInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/show",
      params
    );
  },
  getCampaignsSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/campaigns",
      params
    );
  },
  getCampaignMemberList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/member/index",
      params
    );
  },
  addCampaignMember(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/member/add_member",
      params
    );
  },
  deleteCampaignMember(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/member/delete_member",
      params
    );
  },
  getSalesInteractionList(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/campaign/related-interactive-activities",
      params
    );
  },
  getAccountValidationList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/related-account-validation",
      params
    );
  },
  getCallOutList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/related-task-call-out",
      params
    );
  },
  getQuickInquiryList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/campaign/related-task-quick-inquiry",
      params
    );
  },
};
