import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import {
  MBLayout,
  MBAuth,
  MBSignIn,
  MBError404,
  MBError500,
} from "magicbean-saas-common";
import accountRoutes from "./account";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MBLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      ...accountRoutes,
      // Distribution Channels
      {
        path: "/distribution-network/distribution-channels",
        name: "distribution-channels",
        component: () =>
          import(
            "@/views/distribution-network/distribution-channels/Index.vue"
          ),
      },
      {
        path: "/distribution-network/distribution-channels/:id/Information",
        name: "distribution-channels-information",
        component: () =>
          import(
            "@/views/distribution-network/distribution-channels/Information.vue"
          ),
      },
      // Distribution Price Rules
      {
        path: "/distribution-network/distribution-price-rules",
        name: "distribution-price-rules",
        component: () =>
          import(
            "@/views/distribution-network/distribution-price-rules/Index.vue"
          ),
      },
      {
        path: "/distribution-network/distribution-price-rules/:id/Information",
        name: "distribution-price-rules-information",
        component: () =>
          import(
            "@/views/distribution-network/distribution-price-rules/Information.vue"
          ),
      },
      // influencer-platform/social-connections
      {
        path: "/influencer-platform/social-connections",
        name: "social-connections",
        component: () =>
          import("@/views/influencer-platform/social-connections/Index.vue"),
      },
      {
        path: "/influencer-platform/social-connections/:id/Information",
        name: "social-connections-information",
        component: () =>
          import(
            "@/views/influencer-platform/social-connections/Information.vue"
          ),
      },

      // influencer-platform/capability-models
      {
        path: "/influencer-platform/capability-models",
        name: "capability-models",
        component: () =>
          import("@/views/influencer-platform/capability-models/Index.vue"),
      },
      {
        path: "/influencer-platform/capability-models/:id/information",
        name: "capability-model-information",
        component: () =>
          import(
            "@/views/influencer-platform/capability-models/Information.vue"
          ),
      },

      // influencer-platform/growth-models
      {
        path: "/influencer-platform/growth-models",
        name: "growth-models",
        component: () =>
          import("@/views/influencer-platform/growth-models/Index.vue"),
      },
      {
        path: "/influencer-platform/growth-models/:id/information",
        name: "growth-model-information",
        component: () =>
          import("@/views/influencer-platform/growth-models/Information.vue"),
      },

      // influencer-platform/influencer-groups
      {
        path: "/influencer-platform/influencer-groups",
        name: "influencer-groups",
        component: () =>
          import("@/views/influencer-platform/influencer-groups/Index.vue"),
      },
      {
        path: "/influencer-platform/influencer-groups/:id/Information",
        name: "influencer-groups-information",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-groups/Information.vue"
          ),
      },

      // interactive-marketing/interactive-activities
      {
        path: "/interactive-marketing/interactive-activities",
        name: "interactive-activities",
        component: () =>
          import(
            "@/views/interactive-marketing/interactive-activities/Index.vue"
          ),
      },
      // {
      //   path: "/interactive-marketing/interactive-activity/:id/Information",
      //   name: "interactive-activities-information",
      //   component: () =>
      //     import(
      //       "@/views/interactive-marketing/interactive-activities/Information.vue"
      //     ),
      // },

      {
        path: "/interactive-marketing/interactive-activities/:id/",
        name: "interactive-activities-detail",
        component: () =>
          import(
            "@/views/interactive-marketing/interactive-activities/Detail.vue"
          ),
        children: [
          {
            path: "",
            redirect: "interactive-activities-information",
          },
          {
            path: "information",
            name: "interactive-activities-information",
            component: () =>
              import(
                "@/views/interactive-marketing/interactive-activities/Information.vue"
              ),
          },
          {
            path: "nextsteps",
            name: "interactive-activities-nextsteps",
            component: () =>
              import(
                "@/views/interactive-marketing/interactive-activities/NextSteps.vue"
              ),
          },
        ],
      },

      // interactive-marketing/wechat-communities
      {
        path: "/interactive-marketing/wechat-communities",
        name: "wechat-communities",
        component: () =>
          import("@/views/interactive-marketing/wechat-communities/Index.vue"),
      },
      {
        path: "/interactive-marketing/wechat-communities/:id/Information",
        name: "wechat-community-information",
        component: () =>
          import(
            "@/views/interactive-marketing/wechat-communities/Information.vue"
          ),
      },

      // interactive-marketing/marketing-campaigns
      {
        path: "/interactive-marketing/marketing-campaigns",
        name: "marketing-campaigns",
        component: () =>
          import("@/views/interactive-marketing/marketing-campaigns/Index.vue"),
      },
      {
        path: "/interactive-marketing/marketing-campaigns/:id/Information",
        name: "marketing-campaign-information",
        component: () =>
          import(
            "@/views/interactive-marketing/marketing-campaigns/Information.vue"
          ),
      },
      // task-management/account-validation
      {
        path: "/task-management/account-validation",
        name: "account-validation",
        component: () =>
          import("@/views/task-management/account-validation/Index.vue"),
      },
      {
        path: "/task-management/account-validation/:id/Information",
        name: "account-validation-information",
        component: () =>
          import("@/views/task-management/account-validation/Information.vue"),
      },
      // task-management/call-out-records
      {
        path: "/task-management/call-out-records",
        name: "call-out-records",
        component: () =>
          import("@/views/task-management/call-out-records/Index.vue"),
      },
      {
        path: "/task-management/call-out-records/:id/Information",
        name: "call-out-records-information",
        component: () =>
          import("@/views/task-management/call-out-records/Information.vue"),
      },
      // /task-management/quick-inquiry
      {
        path: "/task-management/quick-inquiry",
        name: "quick-inquiry",
        component: () =>
          import("@/views/task-management/quick-inquiry/Index.vue"),
      },
      {
        path: "/task-management/quick-inquiry/:id/Information",
        name: "quick-inquiry-information",
        component: () =>
          import("@/views/task-management/quick-inquiry/Information.vue"),
      },
      // /task-management/recommendation
      /*{
        path: "/task-management/recommendation",
        name: "recommendation",
        component: () =>
          import("@/views/task-management/recommendation/Index.vue"),
      },
      {
        path: "/task-management/recommendation/:id/Information",
        name: "recommendation-information",
        component: () =>
          import("@/views/task-management/recommendation/Information.vue"),
      },*/
      // task-management/standard-operating-procedure
      {
        path: "/task-management/standard-operating-procedure",
        name: "standard-operating-procedure",
        component: () =>
          import(
            "@/views/task-management/standard-operating-procedure/Index.vue"
          ),
      },
      {
        path: "/task-management/standard-operating-procedure/:id/Information",
        name: "standard-operating-procedure-information",
        component: () =>
          import(
            "@/views/task-management/standard-operating-procedure/Information.vue"
          ),
      },
      // task-management/standard-script
      {
        path: "/task-management/standard-script",
        name: "standard-script",
        component: () =>
          import("@/views/task-management/standard-script/Index.vue"),
      },
      {
        path: "/task-management/standard-script/:id/Information",
        name: "standard-script-information",
        component: () =>
          import("@/views/task-management/standard-script/Information.vue"),
      },
    ],
  },
  {
    path: "/",
    component: MBAuth,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: MBSignIn,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: MBError404,
  },
  {
    path: "/500",
    name: "500",
    component: MBError500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.contentWidth == "fluid") {
    store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
      content: {
        width: "fluid",
      },
    });
  }

  if (to.name !== "sign-in") {
    store.dispatch(Actions.VERIFY_AUTH);
    store.dispatch(Actions.GET_MENU_ITEMS, to.path);
    next();
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
