import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getSocialConnectionList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/index",
      params
    );
  },
  addSocialConnection(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/store",
      params
    );
  },
  updateSocialConnection(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/update",
      params
    );
  },
  deleteSocialConnection(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/delete",
      params
    );
  },
  getSocialConnectionInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/show",
      params
    );
  },
};
