import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getModelGroupList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group/index",
      params
    );
  },
  addModelGroup(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group/store",
      params
    );
  },
  updateModelGroup(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group/update",
      params
    );
  },
  deleteModelGroup(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group/delete",
      params
    );
  },
  getModelGroupInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group/show",
      params
    );
  },

  getModelList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/model/index",
      params
    );
  },
  addModel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/model/store",
      params
    );
  },
  updateModel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/model/update",
      params
    );
  },
  deleteModel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/model/delete",
      params
    );
  },
  getModelInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/model/show",
      params
    );
  },
};
