import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getWechatCommunityList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/index",
      params
    );
  },
  addWechatCommunity(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/store",
      params
    );
  },
  updateWechatCommunity(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/update",
      params
    );
  },
  deleteWechatCommunity(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/delete",
      params
    );
  },
  getWechatCommunityInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/show",
      params
    );
  },
  getWechatCommunityMemberList(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/wechat-community/member/wechat-community-list",
      params
    );
  },
  addWechatCommunityMember(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/member/store",
      params
    );
  },
  deleteWechatCommunityMember(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/member/delete",
      params
    );
  },
  addWechatCommunityCampaign(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/campaign/store",
      params
    );
  },
  deleteWechatCommunityCampaign(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/wechat-community/campaign/delete",
      params
    );
  },
};
