import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  // account-validation
  getAccountValidationList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/index",
      params
    );
  },
  addAccountValidation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/store",
      params
    );
  },
  updateAccountValidation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/update",
      params
    );
  },
  approveAccountValidation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/approve",
      params
    );
  },
  refuseAccountValidation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/refuse",
      params
    );
  },
  deleteAccountValidation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/delete",
      params
    );
  },
  getAccountValidationInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/validation/show",
      params
    );
  },
  // call-out-records
  getCallOutRecordsList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/call-out/index",
      params
    );
  },
  addCallOutRecords(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/call-out/store",
      params
    );
  },
  updateCallOutRecords(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/call-out/update",
      params
    );
  },
  deleteCallOutRecords(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/call-out/delete",
      params
    );
  },
  exportCallOutRecords(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/call-out/export",
      params,
      { responseType: "blob" }
    );
  },
  getCallOutRecordsInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/call-out/show",
      params
    );
  },
  uploadCallOutRecordsExcel() {
    return Config.distributionApiUrl + "/api/task/call-out/import";
  },
  // quick-inquiry
  getQuickInquiryList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/quick-inquiry/index",
      params
    );
  },
  addQuickInquiry(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/quick-inquiry/store",
      params
    );
  },
  updateQuickInquiry(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/quick-inquiry/update",
      params
    );
  },
  deleteQuickInquiry(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/quick-inquiry/delete",
      params
    );
  },
  getQuickInquiryInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/quick-inquiry/show",
      params
    );
  },
  closeQuickInquiry(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/quick-inquiry/close",
      params
    );
  },
  uploadQuickInquiryExcel() {
    return Config.distributionApiUrl + "/api/task/quick-inquiry/import";
  },
  // recommendation
  getRecommendationList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/recommendation/index",
      params
    );
  },
  addRecommendation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/recommendation/store",
      params
    );
  },
  updateRecommendation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/recommendation/update",
      params
    );
  },
  deleteRecommendation(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/recommendation/delete",
      params
    );
  },
  getRecommendationInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/recommendation/show",
      params
    );
  },
  // standard-operating-procedure
  getStandardOperatingProcedureList(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/index",
      params
    );
  },
  addStandardOperatingProcedure(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/store",
      params
    );
  },
  updateStandardOperatingProcedure(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/update",
      params
    );
  },
  deleteStandardOperatingProcedure(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/delete",
      params
    );
  },
  getStandardOperatingProcedureInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/standard-operating-procedure/show",
      params
    );
  },
  // check-list /api/task/standard-operating-procedure/check-list/store
  addStandardOperatingProcedureCheckList(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/check-list/store",
      params
    );
  },
  updateStandardOperatingProcedureCheckList(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/check-list/update",
      params
    );
  },
  deleteStandardOperatingProcedureCheckList(params) {
    return ApiService.post(
      Config.distributionApiUrl +
        "/api/task/standard-operating-procedure/check-list/delete",
      params
    );
  },
  // standard-script
  getStandardScriptList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/standard-script/index",
      params
    );
  },
  addStandardScript(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/standard-script/store",
      params
    );
  },
  updateStandardScript(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/standard-script/update",
      params
    );
  },
  deleteStandardScript(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/standard-script/delete",
      params
    );
  },
  getStandardScriptInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/task/standard-script/show",
      params
    );
  },
  getSopStandardScriptInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/sop-standard-script/list",
      params
    );
  },
};
