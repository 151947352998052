import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getPriceRulesList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/price-rules/index",
      params
    );
  },
  addPriceRules(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/price-rules/store",
      params
    );
  },
  updatePriceRules(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/price-rules/update",
      params
    );
  },
  deletePriceRules(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/price-rules/delete",
      params
    );
  },
  getPriceRulesInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/price-rules/show",
      params
    );
  },
};
