export default [
  {
    path: "/influencer-platform/influencer-accounts",
    name: "accounts-index",
    component: () =>
      import("@/views/influencer-platform/influencer-accounts/Index.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/influencer-platform/influencer-accounts/:id/",
    name: "account-detail",
    component: () =>
      import("@/views/influencer-platform/influencer-accounts/Detail.vue"),
    children: [
      {
        path: "",
        redirect: "influencer-account-overview",
      },
      {
        path: "overview",
        name: "influencer-account-overview",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/Overview.vue"
          ),
      },
      {
        path: "information",
        name: "account-information",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/Information.vue"
          ),
      },
      {
        path: "profile",
        name: "account-profile",
        component: () =>
          import("@/views/influencer-platform/influencer-accounts/Profile.vue"),
      },
      {
        path: "interactions",
        name: "account-interactions",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/Interactions.vue"
          ),
      },
      {
        path: "capability-growth",
        name: "account-capability-growth",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/CapabilityGrowth.vue"
          ),
      },
    ],
  },

  {
    path: "/interactive-marketing/account-conversions",
    name: "account-conversions-index",
    component: () =>
      import("@/views/interactive-marketing/account-conversions/Index.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/interactive-marketing/account-conversions/:id/",
    name: "account-conversions-detail",
    component: () =>
      import("@/views/interactive-marketing/account-conversions/Detail.vue"),
    children: [
      {
        path: "",
        redirect: "account-conversions-overview",
      },
      {
        path: "overview",
        name: "account-conversions-overview",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/Overview.vue"
          ),
      },
      {
        path: "information",
        name: "account-conversions-information",
        component: () =>
          import(
            "@/views/interactive-marketing/account-conversions/Information.vue"
          ),
      },
      {
        path: "profile",
        name: "account-conversions-profile",
        component: () =>
          import("@/views/influencer-platform/influencer-accounts/Profile.vue"),
      },
      {
        path: "interactions",
        name: "account-conversions-interactions",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/Interactions.vue"
          ),
      },
      {
        path: "capability-growth",
        name: "account-conversions-capability-growth",
        component: () =>
          import(
            "@/views/influencer-platform/influencer-accounts/CapabilityGrowth.vue"
          ),
      },
    ],
  },
];
