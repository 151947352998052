import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getGroupLevelList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group-level/index",
      params
    );
  },
  addGroupLevel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group-level/store",
      params
    );
  },
  updateGroupLevel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group-level/update",
      params
    );
  },
  deleteGroupLevel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group-level/delete",
      params
    );
  },
  getGroupLevelInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/group-level/show",
      params
    );
  },
};
