import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getAccountList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/index",
      params
    );
  },
  addAccount(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/store",
      params
    );
  },
  updateAccount(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/update",
      params
    );
  },
  deleteAccount(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/delete",
      params
    );
  },
  getAccountInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/show",
      params
    );
  },

  getAddressList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/address/index",
      params
    );
  },
  addAddress(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/address/store",
      params
    );
  },
  updateAddress(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/address/update",
      params
    );
  },
  deleteAddress(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/address/delete",
      params
    );
  },
  getAddressInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/address/show",
      params
    );
  },

  getSocialConnectionList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/index",
      params
    );
  },
  addSocialConnection(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/store",
      params
    );
  },
  updateSocialConnection(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/update",
      params
    );
  },
  deleteSocialConnection(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/delete",
      params
    );
  },
  getSocialConnectionInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/social-connection/show",
      params
    );
  },
  uploadAccountdPhoto() {
    return Config.distributionApiUrl + "/api/account/upload-photo";
  },
  uploadBcPersonIdReverse() {
    return (
      Config.distributionApiUrl + "/api/account/upload-bc-person-id-reverse"
    );
  },
  uploadBcPersonIdFront() {
    return Config.distributionApiUrl + "/api/account/upload-bc-person-id-front";
  },
  getAccountProfileData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/profile/show",
      params
    );
  },
  updateAccountProfileData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/profile/update",
      params
    );
  },
  getAccountGroupSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/group",
      params
    );
  },
  getAccountCalcuationModelData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/calcuation-model/show",
      params
    );
  },
  updateAccountCalcuationModelData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/calcuation-model/update",
      params
    );
  },

  exportAccountData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/export",
      params,
      { responseType: "blob" }
    );
  },

  getProfileConfigData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/profile/config/show",
      params
    );
  },
  updateProfileConfigData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/profile/config/update",
      params
    );
  },
  runProfileCommand(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/command",
      params
    );
  },
  getAccountCampaignList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/account/campaign-list",
      params
    );
  },
};
