import menu from "magicbean-saas-common/src/core/language/de/menu.json";
import common from "./common.json";
import channel from "./channel.json";
import priceRules from "./priceRule.json";
import influencerAccount from "./influencerAccount.json";
import accountConversions from "./accountConversions.json";
import capabilityModel from "./capabilityModel.json";
import interactiveActivities from "./interactiveActivities.json";
import wechat from "./wechat.json";
import marketingCampaigns from "./marketingCampaigns.json";
import accountValidation from "./accountValidation.json";
import callOutRecords from "./callOutRecords.json";
import quickInquiry from "./quickInquiry.json";
import standardOperatingProcedure from "./standardOperatingProcedure.json";
import standardScript from "./standardScript.json";
import socialConnections from "./socialConnections.json";
import growthModels from "./growthModels.json";
import influencerGroups from "./influencerGroups.json";

export default {
  menu,
  common,
  channel,
  priceRules,
  influencerAccount,
  accountConversions,
  capabilityModel,
  interactiveActivities,
  wechat,
  marketingCampaigns,
  accountValidation,
  callOutRecords,
  quickInquiry,
  standardOperatingProcedure,
  standardScript,
  socialConnections,
  growthModels,
  influencerGroups,
};
